import React, { useState } from 'react'
import { Slider } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase112(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.slider2)
  const [inputValue3, setInputValue3] = useState(props?.data?.slider3)

  const onChange1 = (value) => {
    setInputValue1(value)
  }
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data
    objectData.slider1 = inputValue1
    objectData.slider2 = inputValue2
    objectData.slider3 = inputValue3
    props.handleData(objectData)
  }
  const handleNextPage = async () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Request For Information (RFI)" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #2</div>
        <div className="survey-slider__question headingLegendBold">
          Which of the following describe best of how you spend issue to site
          work?
        </div>
        <div className="survey-slider__textBody">
          <div className="survey-slider__textHeader">
            <div className="">1. being:</div>
            <div className="survey-slider__subTitle">
              We manually compile the pictures of issues we found on the field
              using our phone, retrieve them when returning to the office, then
              download the actuals and upload to email that contains the RFI to
              allow other stakeholders to visualize
            </div>
          </div>
          <div className="survey-slider__textHeader">
            <div className="">10. being:</div>
            <div className="survey-slider__subTitle">
              Issues found during inspection site walk can be linked to RFI for
              sequence of events. RFI with cost and time impact can be used for
              reference for cost management module in a cloud system
            </div>
          </div>
        </div>
        <div className="survey-slider__slider">
          <Slider
            min={1}
            max={10}
            onChange={onChange2}
            marks={marks}
            value={typeof inputValue2 === 'number' ? inputValue2 : 0}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Back" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase112
