import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { InputNumber } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'
import loadMarketoForm from '@helpers/load-marketo-form'
import isInBrowser from '@helpers/is-in-browser'

function UseCase54(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.case5?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.case5?.roi1)
  const [inputValue3, setInputValue3] = useState(props?.data?.case5?.roi2)
  const [inputValue4, setInputValue4] = useState(props?.data?.case5?.roi3)

  const onChange3 = (value) => {
    setInputValue3(value)
  }

  const marketoId = 2853
  let [marketoForm, setMarketoForm] = useState({})
  useEffect(() => {
    const form = window.MktoForms2.getForm(marketoId)
    setMarketoForm(form)
    // loadMarketoForm(marketoId, (marketoFormObj) => {
    //   if (marketoFormObj) {
    //     setMarketoForm(marketoFormObj)
    //     marketoFormObj.onSuccess((values, followUpUrl) => {
    //       window.dataLayer = window.dataLayer || []
    //       window.dataLayer.push({
    //         event: 'page.submit',
    //         'page.submit': {
    //           v: 12,
    //           title: document.title,
    //           product: 'generic',
    //           done: 'true',
    //           id: marketoId,
    //         },
    //       })

    //       return false
    //     })
    //   }
    // })
  }, [])

  const calculateScore = (e) => {
    e.preventDefault()
    let data = props.data
    let scoreCalculator = Math.round(
      ((data.case1.slider1 +
        data.case1.slider2 +
        data.case1.slider3 +
        data.case2.slider1 +
        data.case3.slider1 +
        data.case4.slider1 +
        inputValue1) *
        100) /
        70
    )
    let RFISaving = Math.round(
      data.case1.roi1 * data.case1.roi2 * data.case1.roi3 -
        data.case1.roi1 * 1.5 * data.case1.roi3
    )
    let ICESaving = Math.round(
      data.case2.roi1 * data.case2.roi2 * data.case2.roi3 -
        1.5 * data.case2.roi2 * data.case2.roi3
    )
    let visSaving = Math.round(
      data.case3.roi1 * data.case3.roi2 * data.case3.roi3 -
        0.5 * data.case3.roi2 * data.case3.roi3
    )
    let isoSaving = Math.round(
      data.case4.roi1 * data.case4.roi2 * data.case4.roi3 -
        0.15 * data.case4.roi2 * data.case4.roi3
    )
    let ddmSaving = Math.round(
      inputValue2 * inputValue3 * inputValue4 - 0.5 * inputValue3 * inputValue4
    )

    let savingNum = RFISaving + ICESaving + visSaving + isoSaving + ddmSaving

    const userDetails = {
      FirstName: sessionStorage.getItem('FirstName') || '-',
      LastName: sessionStorage.getItem('LastName') || '-',
      Title: sessionStorage.getItem('Title') || '-',
      Company: sessionStorage.getItem('Company') || '-',
      Phone: sessionStorage.getItem('Phone') || '-',
      Email: sessionStorage.getItem('Email') || '-',
    }

    const unitSlider = ' out of 10'
    const unitRFIsPerYear = ' RFIs per year'
    const unitHoursPerRFI = ' hours per RFI'
    const unitWagePerHour = ' wage per hour'
    const unitHours = ' hours'
    const unitResolutionsPerYear = ' meeting resolutions per year'
    const unitAvgHourlyWage = ' average hourly wage'
    const unitReports = ' reports'
    const unitSubmissionsPerYear = ' submissions per year'
    const unitInspectionsPerYear = ' inspections per year'

    const units = {
      uc1q1Unit: unitSlider,
      uc1q2Unit: unitSlider,
      uc1q3Unit: unitSlider,
      uc1q4Unit: unitRFIsPerYear,
      uc1q5Unit: unitHoursPerRFI,
      uc1q6Unit: unitWagePerHour,
      uc2q1Unit: unitSlider,
      uc2q2Unit: unitHours,
      uc2q3Unit: unitResolutionsPerYear,
      uc2q4Unit: unitAvgHourlyWage,
      uc3q1Unit: unitSlider,
      uc3q2Unit: unitHours,
      uc3q3Unit: unitReports,
      uc3q4Unit: unitAvgHourlyWage,
      uc4q1Unit: unitSlider,
      uc4q2Unit: unitHours,
      uc4q3Unit: unitSubmissionsPerYear,
      uc4q4Unit: unitAvgHourlyWage,
      uc5q1Unit: unitSlider,
      uc5q2Unit: unitHours,
      uc5q3Unit: unitAvgHourlyWage,
      uc5q4Unit: unitInspectionsPerYear,
    }

    let dataSave = {
      uc1q1: data.case1.slider1,
      uc1q2: data.case1.slider2,
      uc1q3: data.case1.slider3,
      uc1q4: data.case1.roi1,
      uc1q5: data.case1.roi2,
      uc1q6: data.case1.roi3,
      uc2q1: data.case2.slider1,
      uc2q2: data.case2.roi1,
      uc2q3: data.case2.roi2,
      uc2q4: data.case2.roi3,
      uc3q1: data.case3.slider1,
      uc3q2: data.case3.roi1,
      uc3q3: data.case3.roi2,
      uc3q4: data.case3.roi3,
      uc4q1: data.case4.slider1,
      uc4q2: data.case4.roi1,
      uc4q3: data.case4.roi2,
      uc4q4: data.case4.roi3,
      uc5q1: inputValue1,
      uc5q2: inputValue2,
      uc5q3: inputValue3,
      uc5q4: inputValue4,
      savings: savingNum?.toLocaleString(),
      dataScore: scoreCalculator?.toLocaleString(),
      rfi: RFISaving?.toLocaleString(),
      ice: ICESaving?.toLocaleString(),
      vis: visSaving?.toLocaleString(),
      iso: isoSaving?.toLocaleString(),
      ddm: ddmSaving?.toLocaleString(),
      userDetails,
    }

    const formatStr = () => {
      const divider = '                         \n  '
      let result = 'IDD Formatted Survey Results: ' + divider
      const useCaseDimensions = [6, 4, 4, 4, 4]
      useCaseDimensions.forEach((quantity, ucI) => {
        result += `USE CASE ${ucI + 1}: ` + divider
        for (let qI = 1; qI <= quantity; qI++) {
          const key = `uc${ucI + 1}q${qI}`
          result +=
            `USE CASE ${ucI + 1} QUESTION ${qI} (${key}): ` +
            dataSave[key] +
            units[`${key}Unit`] +
            divider
        }
        result += divider
      })

      const calculated = [
        'savings',
        'dataScore',
        'rfi',
        'ice',
        'vis',
        'iso',
        'ddm',
      ]
      calculated.forEach(
        (key) => (result += `${key}: ` + dataSave[key] + divider)
      )

      const userData = [
        'Email',
        'FirstName',
        'LastName',
        'Title',
        'Company',
        'Phone',
      ]
      result += divider + `USER DETAILS: ` + divider
      userData.forEach(
        (key) => (result += `${key}: ` + dataSave.userDetails[key] + divider)
      )

      return result
    }

    const formattedStr = formatStr()

    if (process.env.GATSBY_ENV !== 'prod')
      console.log('FORMATTEDSTR', formattedStr)

    sessionStorage.setItem('iDDSurveyCapture', formattedStr)
    sessionStorage.setItem('savings', savingNum.toLocaleString())
    sessionStorage.setItem('dataScore', scoreCalculator?.toLocaleString())
    sessionStorage.setItem('rfi', RFISaving?.toLocaleString())
    sessionStorage.setItem('ice', ICESaving?.toLocaleString())
    sessionStorage.setItem('vis', visSaving?.toLocaleString())
    sessionStorage.setItem('iso', isoSaving?.toLocaleString())
    sessionStorage.setItem('ddm', ddmSaving?.toLocaleString())

    const formValues = {
      ...userDetails,
      iDDSurveyCapture: formattedStr,
      optinDataCollection: true,
      optinMarketingCommunications: true,
    }

    if (isInBrowser) {
      window?.analytics?.identify(
        null,
        {
          email: userDetails.Email,
          name: `${userDetails.FirstName} ${userDetails.LastName}`,
        },
        null,
        () =>
          window?.analytics?.track('acc.mktg_apac_survey.complete', dataSave)
      )

      marketoForm?.setValues(formValues)
      if (process.env.GATSBY_ENV !== 'prod')
        console.log('MARKETO FORM VALUES', marketoForm?.vals())
      const validated = marketoForm?.validate()
      if (validated) {
        window.MktoForms2.whenReady(function (form) {
          form.submit()
          navigate(`/thank-you`)
        })
      } else {
        marketoForm?.showErrorMessage()
      }
    }
  }

  const handlerData = () => {
    let objectData = props.data
    objectData.slider1 = inputValue1
    objectData.roi1 = inputValue2
    objectData.roi2 = inputValue3
    props.handleData(objectData)
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Defects Management" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #4</div>
        <div className="survey-slider__question headingLegendBold">
          How many inspections do you have to conduct in a year?
        </div>
        <div className="survey-slider__inputNumber">
          <InputNumber
            min={0}
            max={100}
            style={{ margin: '0 16px' }}
            value={inputValue3}
            onChange={onChange3}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Back" />
          <div className="horizontal-space" />
          <Button onClick={calculateScore} label="Complete" />
        </div>
      </div>
    </>
  )
}

export default UseCase54
