import React, { useState } from 'react'
import { Slider } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase111(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.slider2)
  const [inputValue3, setInputValue3] = useState(props?.data?.slider3)

  const onChange1 = (value) => {
    setInputValue1(value)
  }
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data
    objectData.slider1 = inputValue1
    objectData.slider2 = inputValue2
    objectData.slider3 = inputValue3
    props.handleData(objectData)
  }
  const handleNextPage = async () => {
    handlerData()
    return props.next()
  }
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Request For Information (RFI)" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #1</div>
        <div className="survey-slider__question headingLegendBold">
          Which of the following best describes your current RFI process?
        </div>
        <div className="survey-slider__textBody">
          <div className="survey-slider__textHeader">
            <div className="">1. being:</div>
            <div className="survey-slider__subTitle">
              RFIs are created and submitted manually through emails and typed
              into a spreadsheet to track response. Tracking is done manually
              and monitoring of incoming requests on RFI is also done by email.
              Replies and mark-ups on 20 drawings in PDF are extracted from BIM 
              design and coordination tools and re-uploaded to the tools afterwards
            </div>
          </div>
          <div className="survey-slider__textHeader-spacer" />
          <div className="survey-slider__textHeader">
            <div className="">10. being:</div>
            <div className="survey-slider__subTitle">
              RFIs are created directly from the cloud and linking issues done
              on drawings. Redline directly in the cloud on drawings. Person
              responsible clicks on the notification link for the RFI assigned
              to them. There is a RFI Resolution summary dashboard to allow
              stakeholders to monitor due dates set by stakeholders
            </div>
          </div>
        </div>
        <div className="survey-slider__slider">
          <Slider
            min={1}
            max={10}
            onChange={onChange1}
            marks={marks}
            value={typeof inputValue1 === 'number' ? inputValue1 : 0}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          {/* <Button onClick={handleBackPage} label="Back Page" /> */}
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase111
