import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://idd.construction.autodesk.com/" />

        <title>
          Integrated Digital Delivery: IDD Readiness Assessment Singapore{' '}
        </title>
        <meta
          name="keywords"
          content="IDD,Digital Construction,BCA,Singapore,Integrated Digital Delivery"
        />
        <meta
          name="description"
          content="Based on the Integrated Digital Delivery (IDD) framework of the Building and Construction Authority Singapore, take this free digital construction readiness assessment and discover potential savings unlocked by cloud solutions."
        />

        <meta
          property="og:title"
          content="IDD: Digital Construction Readiness Assessment"
        />
        <meta
          property="og:description"
          content="Take this free digital construction readiness assessment and discover potential savings unlocked by cloud solutions. Developed based on the Integrated Digital Delivery (IDD) framework of the Building and Construction Authority Singapore."
        />
        <meta
          property="og:image"
          content="https://a.storyblok.com/f/64835/x/f4f43c2150/dbpo-isos.svg"
        />

        <meta property="og:site_name" content="IDD Readiness Assessment" />
        <meta
          property="og:url"
          content="https://idd.construction.autodesk.com"
        />
        <script
          src="https://fast.wistia.com/embed/medias/8gygjol30t.jsonp"
          async
        ></script>
        <script
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
        ></script>
      </Helmet>
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
