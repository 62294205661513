import React, { useState } from 'react'
import { Slider } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase4(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.roi1)
  const [inputValue3, setInputValue3] = useState(props?.data?.roi2)
  const onChange1 = (value) => {
    setInputValue1(value)
  }
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data
    objectData.slider1 = inputValue1
    objectData.roi1 = inputValue2
    objectData.roi2 = inputValue3
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Design Documentation submission and approval ISO19650" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #1</div>
        <div className="survey-slider__question headingLegendBold">
          Which of the following best describes your current process of
          submitting design documentation for approval of ISO 19650?
        </div>
        <div className="survey-slider__textBody">
          <div className="survey-slider__textHeader">
            <div className="">1. being:</div>
            <div className="survey-slider__subTitle">
              Documents and drawings shared are not reviewed or validated.
              Seeking and tracking of approvals are done via emails. Documents
              are not uploaded to CDE after email approvals are given. CDE does 
              not allow opening in BIM design and coordination tools, and data 
              is lost in File Conversion workflows.
              Request for approval for material procurement does not exist
            </div>
          </div>
          <div className="survey-slider__textHeader">
            <div className="">10. being:</div>
            <div className="survey-slider__subTitle">
              Deliverables required by various stakeholders for decision making
              are done through digital means. Review of document/drawings is
              done in the cloud for digital doc approval workflows. Team members
              are allowed to view the drawings, documents in a cloud system to
              compare with previous versions before deciding if approval should
              be given. Request for approval is facilitated in the CDE allowing
              stakeholders to ensure materials are approved by QP/PE before
              procurement
            </div>
          </div>
        </div>
        <div className="survey-slider__slider">
          <Slider
            min={1}
            max={10}
            marks={marks}
            onChange={onChange1}
            value={typeof inputValue1 === 'number' ? inputValue1 : 0}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Previous Case" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase4
