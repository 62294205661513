import React, { useState } from 'react'
import { InputNumber } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase44(props) {
  const [inputValue3, setInputValue3] = useState(props?.data?.roi3)
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data
    objectData.roi3 = inputValue3
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Design Documentation submission and approval ISO19650" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #4</div>
        <div className="survey-slider__question headingLegendBold">
          What is the average hourly wage for managing ISO 19650 submission?
        </div>
        <div className="survey-slider__inputNumber">
          <InputNumber
            min={0}
            max={100}
            style={{ margin: '0 16px' }}
            value={inputValue3}
            onChange={onChange3}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Back" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next Case" />
        </div>
      </div>
    </>
  )
}

export default UseCase44
