import React, { useState } from 'react'
import { Steps } from 'antd'
import Wrapper from '@components/Wrapper/Wrapper'
import UseCase111 from './UseCases/UseCase111'
import UseCase112 from './UseCases/UseCase112'
import UseCase113 from './UseCases/UseCase113'
import UseCase121 from './UseCases/UseCase121'
import UseCase122 from './UseCases/UseCase122'
import UseCase123 from './UseCases/UseCase123'
import UseCase21 from './UseCases/UseCase21'
import UseCase22 from './UseCases/UseCase22'
import UseCase23 from './UseCases/UseCase23'
import UseCase24 from './UseCases/UseCase24'
import UseCase31 from './UseCases/UseCase31'
import UseCase32 from './UseCases/UseCase32'
import UseCase33 from './UseCases/UseCase33'
import UseCase34 from './UseCases/UseCase34'
import UseCase41 from './UseCases/UseCase41'
import UseCase42 from './UseCases/UseCase42'
import UseCase43 from './UseCases/UseCase43'
import UseCase44 from './UseCases/UseCase44'
import UseCase51 from './UseCases/UseCase51'
import UseCase52 from './UseCases/UseCase52'
import UseCase54 from './UseCases/UseCase54'
import UseCase53 from './UseCases/UseCase53'

const { Step } = Steps

const SurveySlider = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [stepPage, setStepPage] = useState(0)
  const [data, setData] = useState({
    case1: {
      slider1: 1,
      slider2: 1,
      slider3: 1,
      roi1: 0,
      roi2: 0,
      roi3: 0,
    },
    case2: {
      slider1: 1,
      roi1: 0,
      roi2: 0,
      roi3: 0,
    },
    case3: {
      slider1: 1,
      roi1: 0,
      roi2: 0,
      roi3: 0,
    },
    case4: {
      slider1: 1,
      roi1: 0,
      roi2: 0,
      roi3: 0,
    },
    case5: {
      slider1: 1,
      roi1: 0,
      roi2: 0,
      roi3: 0,
    },
  })
  const handleSetData1 = (value) => {
    let dataObject = data
    dataObject.case1 = value
    setData(dataObject)
  }
  const handleSetData2 = (value) => {
    let dataObject = data
    dataObject.case2 = value
    setData(dataObject)
  }
  const handleSetData3 = (value) => {
    let dataObject = data
    dataObject.case3 = value
    setData(dataObject)
  }
  const handleSetData4 = (value) => {
    let dataObject = data
    dataObject.case4 = value
    setData(dataObject)
  }
  const handleSetData5 = (value) => {
    let dataObject = data
    dataObject.case5 = value
    setData(dataObject)
  }
  const currentChange = (value) => {
    setCurrentStep(value)
  }
  const stepPageChange = (value) => {
    if (value === 0) {
      setCurrentStep(0)
    }
    setStepPage(value)
  }
  return (
    <Wrapper>
      <div className="survey-slider">
        <Steps
          type="navigation"
          current={currentStep}
          // onChange={this.onChange}
          className="site-navigation-steps"
        >
          <Step status="finish" title="Use Case 1" />
          <Step status="" title="Use Case 2" />
          <Step status="" title="Use Case 3" />
          <Step status="" title="Use Case 4" />
          <Step status="" title="Use Case 5" />
        </Steps>
        {currentStep === 0 && stepPage === 0 && (
          <UseCase111
            data={data.case1}
            handleData={handleSetData1}
            next={() => stepPageChange(1)}
          />
        )}
        {currentStep === 0 && stepPage === 1 && (
          <UseCase112
            data={data.case1}
            handleData={handleSetData1}
            next={() => stepPageChange(2)}
            back={() => stepPageChange(0)}
          />
        )}
        {currentStep === 0 && stepPage === 2 && (
          <UseCase113
            data={data.case1}
            handleData={handleSetData1}
            next={() => stepPageChange(3)}
            back={() => stepPageChange(1)}
          />
        )}
        {currentStep === 0 && stepPage === 3 && (
          <UseCase121
            data={data.case1}
            handleData={handleSetData1}
            next={() => stepPageChange(4)}
            back={() => stepPageChange(2)}
          />
        )}
        {currentStep === 0 && stepPage === 4 && (
          <UseCase122
            data={data.case1}
            handleData={handleSetData1}
            next={() => stepPageChange(5)}
            back={() => stepPageChange(3)}
          />
        )}
        {currentStep === 0 && stepPage === 5 && (
          <UseCase123
            data={data.case1}
            handleData={handleSetData1}
            next={() => currentChange(1)}
            back={() => stepPageChange(4)}
          />
        )}
        {currentStep === 1 && stepPage === 5 && (
          <UseCase21
            data={data.case2}
            handleData={handleSetData2}
            next={() => stepPageChange(6)}
            back={() => currentChange(0)}
          />
        )}
        {currentStep === 1 && stepPage === 6 && (
          <UseCase22
            data={data.case2}
            handleData={handleSetData2}
            next={() => stepPageChange(7)}
            back={() => stepPageChange(5)}
          />
        )}
        {currentStep === 1 && stepPage === 7 && (
          <UseCase23
            data={data.case2}
            handleData={handleSetData2}
            next={() => stepPageChange(8)}
            back={() => stepPageChange(6)}
          />
        )}
        {currentStep === 1 && stepPage === 8 && (
          <UseCase24
            data={data.case2}
            handleData={handleSetData2}
            next={() => currentChange(2)}
            back={() => stepPageChange(7)}
          />
        )}
        {currentStep === 2 && stepPage === 8 && (
          <UseCase31
            data={data.case3}
            handleData={handleSetData3}
            next={() => stepPageChange(9)}
            back={() => currentChange(1)}
          />
        )}
        {currentStep === 2 && stepPage === 9 && (
          <UseCase32
            data={data.case3}
            handleData={handleSetData3}
            next={() => stepPageChange(10)}
            back={() => stepPageChange(8)}
          />
        )}
        {currentStep === 2 && stepPage === 10 && (
          <UseCase33
            data={data.case3}
            handleData={handleSetData3}
            next={() => stepPageChange(11)}
            back={() => stepPageChange(9)}
          />
        )}
        {currentStep === 2 && stepPage === 11 && (
          <UseCase34
            data={data.case3}
            handleData={handleSetData3}
            next={() => currentChange(3)}
            back={() => stepPageChange(10)}
          />
        )}
        {currentStep === 3 && stepPage === 11 && (
          <UseCase41
            data={data.case4}
            handleData={handleSetData4}
            next={() => stepPageChange(12)}
            back={() => currentChange(2)}
          />
        )}
        {currentStep === 3 && stepPage === 12 && (
          <UseCase42
            data={data.case4}
            handleData={handleSetData4}
            next={() => stepPageChange(13)}
            back={() => stepPageChange(11)}
          />
        )}
        {currentStep === 3 && stepPage === 13 && (
          <UseCase43
            data={data.case4}
            handleData={handleSetData4}
            next={() => stepPageChange(14)}
            back={() => stepPageChange(12)}
          />
        )}
        {currentStep === 3 && stepPage === 14 && (
          <UseCase44
            data={data.case4}
            handleData={handleSetData4}
            next={() => currentChange(4)}
            back={() => stepPageChange(13)}
          />
        )}
        {currentStep === 4 && stepPage === 14 && (
          <UseCase51
            data={data}
            handleData={handleSetData5}
            next={() => stepPageChange(15)}
            back={() => currentChange(3)}
          />
        )}
        {currentStep === 4 && stepPage === 15 && (
          <UseCase52
            data={data}
            handleData={handleSetData5}
            next={() => stepPageChange(16)}
            back={() => stepPageChange(14)}
          />
        )}
        {currentStep === 4 && stepPage === 16 && (
          <UseCase53
            data={data}
            handleData={handleSetData5}
            next={() => stepPageChange(17)}
            back={() => stepPageChange(15)}
          />
        )}
        {currentStep === 4 && stepPage === 17 && (
          <UseCase54
            data={data}
            handleData={handleSetData5}
            next={() => currentChange(3)}
            back={() => stepPageChange(16)}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default SurveySlider
