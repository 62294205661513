import React, { useState } from 'react'
import { Slider } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase51(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.case5?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.case5?.roi1)
  const [inputValue3, setInputValue3] = useState(props?.data?.case5?.roi2)
  const onChange1 = (value) => {
    setInputValue1(value)
  }
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data.case5
    objectData.slider1 = inputValue1
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Defects Management" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #1</div>
        <div className="survey-slider__question headingLegendBold">
          Which of the following best describes your current process of managing
          defects?
        </div>
        <div className="survey-slider__textBody">
          <div className="survey-slider__textHeader">
            <div className="">1. being:</div>
            <div className="survey-slider__subTitle">
              Site inspector needs to take photos, download them to the
              computer, and update reports in word document, pdf manually and
              email them to sub contractors and key into excel for issue record
              tracking
            </div>
          </div>
          <div className="survey-slider__textHeader">
            <div className="">10. being:</div>
            <div className="survey-slider__subTitle">
              Master defects list is available. The team can lodge, manage, and
              track quality issues and defects using mobile application and
              improve rectification using digital via dashboards
            </div>
          </div>
        </div>
        <div className="survey-slider__slider">
          <Slider
            min={1}
            max={10}
            marks={marks}
            onChange={onChange1}
            value={typeof inputValue1 === 'number' ? inputValue1 : 0}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Previous Case" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase51
