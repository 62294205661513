import React from 'react'
import Navigation from '../Navigation/Navigation'
import Layout from "../layout"

const Wrapper = ({ children }) => {
  return (
    <Layout>
      <Navigation />
      <div id="idd-survey">{children}</div>
    </Layout>
  )
}

export default Wrapper
