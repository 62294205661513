import React, { useState } from 'react'
import { Slider } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase21(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.slider1)
  const onChange1 = (value) => {
    setInputValue1(value)
  }

  const handlerData = () => {
    let objectData = props.data
    objectData.slider1 = inputValue1
    objectData.roi1 = props?.data?.roi1
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }
  return (
    <>
      <QuestionsHeader titleHead="Meeting Minutes for Integrated Concurrent Engineering (ICE)" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #1</div>
        <div className="survey-slider__question headingLegendBold">
          Which of the following best describes your current process of managing
          meeting minutes?
        </div>
        <div className="survey-slider__textBody">
          <div className="survey-slider__textHeader">
            <div className="">1. being:</div>
            <div className="survey-slider__subTitle">
              Meeting minutes are manually typed into word or excel spreadsheet
              documents. No traceability on what issue, RFI, and clashes was
              discussed as project commences
            </div>
          </div>
          <div className="survey-slider__textHeader">
            <div className="">10. being:</div>
            <div className="survey-slider__subTitle">
              Digital records of decisions and actions to be taken and party
              responsible. We have the ability to invite attendees and assign
              agenda and task digitally, as well as append issues, RFI documents
              in meeting agenda and assign to respective meeting invitees
            </div>
          </div>
        </div>
        <div className="survey-slider__slider">
          <Slider
            min={1}
            max={10}
            marks={marks}
            onChange={onChange1}
            value={typeof inputValue1 === 'number' ? inputValue1 : 0}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Previous Case" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase21
