import React from 'react'
import logo from '@images/logo.png'
import { Image } from 'react-bootstrap'

const Navigation = () => {
  return (
    <div className="navigation">
      <Image src={logo} fluid={true} className="navigation__logo" />
    </div>
  )
}

export default Navigation
