import React, { useState } from 'react'
import { InputNumber } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase52(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.case5?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.case5?.roi1)
  const [inputValue3, setInputValue3] = useState(props?.data?.case5?.roi2)
  const onChange1 = (value) => {
    setInputValue1(value)
  }
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data.case5
    objectData.roi1 = inputValue2
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }

  return (
    <>
      <QuestionsHeader titleHead="Digital Defects Management" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #2</div>
        <div className="survey-slider__question headingLegendBold">
          How many hours does it take you to manage your defects from
          identifying to reporting and tracking?
        </div>
        <div className="survey-slider__inputNumber">
          <InputNumber
            min={0}
            max={100}
            style={{ margin: '0 16px' }}
            value={inputValue2}
            onChange={onChange2}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Back" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase52
